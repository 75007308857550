import { render, staticRenderFns } from "./publicPerformance.vue?vue&type=template&id=1d379128&scoped=true&"
import script from "./publicPerformance.vue?vue&type=script&lang=js&"
export * from "./publicPerformance.vue?vue&type=script&lang=js&"
import style0 from "./publicPerformance.vue?vue&type=style&index=0&id=1d379128&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d379128",
  null
  
)

export default component.exports