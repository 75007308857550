<template>
  <div>
    <div class="company-dropdown">
      <img :src="$store.state.activeCompany.logo" alt="" @click="toCompanyIntroduction" />
      <el-dropdown @command="changeCompany" trigger="click">
        <span class="company-name">
          {{ $store.state.activeCompany.label }}
        </span>
        <i class="icon-main-xiajiantou"></i>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in $store.state.companyList"
            :key="item.value"
            :command="item"
          >
            {{ item.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <hb-top-bar v-show="!$store.state.fullScreen">
      <div slot="search">
        <div class="search-wrapper">
          <hb-select-vague
            :key="key"
            clearable
            class="full-search"
            style="width: 260px"
            size="mini"
            v-model="fullPath"
            @change="nav"
            :options="toolOpt"
            :defaultProps="defaultProps"
            placeholder="全局检索，支持首拼或全拼检索"
          ></hb-select-vague>
          <el-dropdown class="drop" trigger="click" size="mini" @command="toSearchEngine">
            <span class="el-dropdown-link">
              <i style="font-size: 13px; padding: 0 5px" class="icon-main-sousuo"></i>
              AR搜索
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                style="width: 55px"
                v-for="item in links"
                :key="item.link"
                :command="item.link"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </hb-top-bar>
    <div class="micro-main-app__container">
      <hb-navmenu
        v-show="!$store.state.fullScreen"
        :data="microNavs"
        baseUrl="/micro-platform"
        @customClick="toggleNavFolder"
        @customClose="navFolder = false"
      >
        <div slot="userlogo" class="logo-wrapper">
          <el-dropdown @command="usersActions" trigger="click">
            <img
              :src="$store.state.userInfo.logo || defaultUserLogo"
              width="38"
              height="38"
              alt=""
            />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in userTools"
                :key="index"
                :command="item.value"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </hb-navmenu>
      <router-view></router-view>
      <!-- 微应用挂载节点 -->
      <div class="micro-main-app__content" id="microAppContainer" v-show="fullLoading"></div>
      <Upload ref="netdisc" />
      <div class="nav-list" v-show="navFolder">
        <i class="el-icon-close close-btn" @click="toggleNavFolder"></i>
        <div class="tools">
          <div class="scroll">
            <template>
              <div class="system" v-for="system in systemList" :key="system.code">
                <div class="system-name">{{ system.name }}</div>
                <div class="tool-wrapper flex">
                  <div class="title">工具</div>
                  <div class="menus flex">
                    <div class="menu" v-for="tool in system.child.subNode" :key="tool.id">
                      <div class="system-items">
                        <div class="item" @click="nav(tool.fullPath, true)">
                          <HbSvg
                            className="icon"
                            fontSize="20px"
                            :iconClass="tool.icon || ''"
                          ></HbSvg>
                          <div class="item-name">{{ tool.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tool-wrapper flex">
                  <div class="title">设置</div>
                  <div class="menus flex">
                    <div class="menu" v-for="tool in system.child.subNodeSet" :key="tool.id">
                      <div class="system-items">
                        <div class="item" @click="nav(tool.fullPath, true)">
                          <HbSvg
                            className="icon"
                            fontSize="20px"
                            :iconClass="tool.icon || ''"
                          ></HbSvg>

                          <div class="item-name">{{ tool.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <hb-dialog ref="logout" title="请确认是否退出登录？" width="520px">
      <div style="height: 50px" slot="normal">
        安全退出会清除您的登录信息，建议您在非常用设备上使用安全退出。
      </div>
      <div slot="dialogFooter">
        <el-button @click="cancel">取消</el-button>
        <el-button :disabled="loading" @click="logout(true, false, true)" :loading="safeLoading">
          安全退出
        </el-button>
        <el-button
          type="primary"
          :disabled="safeLoading"
          :loading="loading"
          @click="logout(false, false, true)"
        >
          退出登录
        </el-button>
      </div>
    </hb-dialog>
    <Draft ref="draft" />
    <AnDeng ref="anDeng" />
    <Approval ref="approval" />
    <Task ref="task" />
    <MaterialDetailDraw ref="materialDetailDraw" />
  </div>
</template>

<script>
import defaultUserLogo from "@/assets/images/user-logo--default.png";
import { addGlobalUncaughtErrorHandler } from "qiankun";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import registerMicroServe from "../config/qiankun";
import Upload from "./netdisc/Upload.vue";
import { mainMethods } from "@/assets/js/globalUtils.js";
import Draft from "./draft/Draft.vue";
import AnDeng from "./light/Index.vue";
import Approval from "./approval/Index.vue";
import Task from "./task/Index.vue";
import MaterialDetailDraw from "./material/Index.vue";

export default {
  name: "container",
  data() {
    return {
      fullLoading: false,
      safeLoading: false,
      loading: false,
      microNavs: [
        {
          id: "1470569839176712265",
          label: "工作台",
          icon: "icon-main-shouye",
          path: "",
          subMenus: [],
        },
        {
          id: "1470569839185100899",
          label: "网盘",
          icon: "icon-main-a-ISOguanliwangpan",
          path: "/netdisc",
          subMenus: [],
        },
        {
          id: "1470569839185100890",
          label: "工具",
          icon: "icon-main-gongju",
          path: "/tools",
          event: true,
          subMenus: [],
        },
      ], //当前激活微应用的导航数据
      navFolder: false,
      systemList: [],
      key: new Date().getTime(),
      defaultUserLogo,
      userTools: [
        {
          label: "个人信息",
          value: "1",
        },
        {
          label: "修改密码",
          value: "2",
        },
        {
          label: "退出登录",
          value: "3",
        },
      ],
      fullPath: "",
      toolOpt: [],
      defaultProps: {
        value: "fullPath",
        label: "name",
      },
      links: [
        {
          name: "微软",
          link: "https://cn.bing.com",
        },
        {
          name: "百度",
          link: "https://www.baidu.com",
        },
        {
          name: "讯飞",
          link: "http://ai.xyzop.com",
        },
        {
          name: "360",
          link: "https://www.so.com",
        },
        {
          name: "腾讯",
          link: "http://soso.com",
        },
        {
          name: "阿里",
          link: "https://aliso.cc",
        },
        {
          name: "kimi",
          link: "https://kimi.moonshot.cn",
        },
      ],
    };
  },
  computed: {
    ...mapState(["userInfo", "activeApp", "companyList"]),
    ...mapGetters(["activeAppSysId"]),
  },
  created() {
    this.init();
    window.addEventListener("showNetdisc", this.showNetdisc);
    window.addEventListener("viewDraft", this.viewDraft);
    window.addEventListener("saveDraft", this.saveDraft);
    window.addEventListener("deleteDraft", this.deleteDraft);
    window.addEventListener("changeLight", this.changeLight);
    window.addEventListener("openApproval", this.openApproval);
    window.addEventListener("dispatchTask", this.dispatchTask);
    window.addEventListener("openMaterialDetail", this.openMaterialDetail);
  },
  methods: {
    openMaterialDetail({ detail }) {
      this.$refs.materialDetailDraw.open(detail);
    },
    dispatchTask({ detail }) {
      this.$refs.task.dispatchDialog(detail);
    },
    changeLight({ detail }) {
      this.$refs.anDeng.open(detail);
    },
    openApproval({ detail }) {
      this.$refs.approval.open(detail);
    },
    deleteDraft({ detail }) {
      this.$http.get("/userDraft/removeUserDraft?id=" + detail.id, {
        baseURL: "/platform",
      });
    },
    viewDraft({ detail }) {
      this.$refs.draft.open(detail.sysCode, detail.sourceType);
    },
    saveDraft({ detail }) {
      this.$refs.draft.openTitle(detail.sysCode, detail.sourceType, detail.id, detail.data);
    },
    ...mapActions([
      "getCompanyList",
      "getUsersList",
      "getOrganizationData",
      "getFullOrganizationData",
      "getUserInfo",
      "getLevelDeptData",
    ]),
    showNetdisc() {
      this.$refs.netdisc.visible = !this.$refs.netdisc.visible;
      this.$refs.netdisc.smalltype = false;
    },
    ...mapMutations([
      "setUserInfo",
      "setAuthorization",
      "setMicroRoutes",
      "setActiveCompany",
      "setPermissionMemuPaths",
    ]),
    init() {
      this.initSystem();
      this.initStore();
      this.initPermissions();
    },
    //初始化系统，检查核心数据
    initSystem() {
      this.setAuthorization();
      this.getCompanyList().then((res) => {
        let cache = window.localStorage.getItem("activeCompany");
        if (cache) {
          this.setActiveCompany(JSON.parse(cache));
        } else {
          this.setActiveCompany(res[0]);
        }
      });
      this.getUserInfo();
    },
    initStore() {
      this.setMicroRoutes(JSON.parse(sessionStorage.getItem("microRoutes")));
      this.getOrganizationData();
      this.getUsersList();
      this.getFullOrganizationData();
      this.getLevelDeptData();
    },
    initPermissions() {
      this.getAllTools(), this.getMyTools();
    },
    getAllTools() {
      return this.$http.get("/userMenu/getAllCommonlyUsedFunctions").then((res) => {
        let tools = [];
        res.forEach((item) => {
          item.menus.forEach((i) => {
            tools = tools.concat(i.subMenus || []);
          });
        });
        tools.forEach((item) => {
          item.basePath = this.$store.state.microApps.find(
            (i) => i.systemCode === item.sysCode,
          )?.path;
          item.fullPath = item.basePath + item.path;
        });
        this.toolOpt = tools;
      });
    },
    getMyTools() {
      let p1 = this.$http.get("/tool/getAllCommonlyUsedFunctions");
      let p2 = this.$http.get("/userMenu/getToolClassification");
      return Promise.all([p1, p2]).then((arr) => {
        let node = arr[1];
        let subNode = arr[0];
        subNode.forEach((item) => {
          item.fullPath =
            this.$store.state.microApps.find((ele) => ele.systemCode === item.sysCode).path +
            item.path;
        });
        this.setPermissionMemuPaths(subNode.map((i) => i.fullPath));
        this.fullLoading = true;
        try {
          node.forEach((i) => {
            i.child = {};
            i.child.subNode = subNode
              .filter((j) => j.toolClassification === i.code && !j.ifSet)
              .sort((a, b) => Number(a.toolOrder) - Number(b.toolOrder));
            i.child.subNodeSet = subNode
              .filter((j) => j.toolClassification === i.code && j.ifSet)
              .sort((a, b) => Number(a.toolOrder) - Number(b.toolOrder));
          });
        } catch (error) {
          console.log(error);
        }
        this.systemList = node;

        console.log(this.systemList, 111);
      });
    },
    toggleNavFolder() {
      this.navFolder = !this.navFolder;
    },
    nav(fullPath, byNav) {
      if (byNav) {
        this.navFolder = false;
      } else {
        this.fullPath = "";
        this.key = new Date().getTime();
      }
      this.$router.push(fullPath);
    },
    changeCompany(company) {
      this.$http.post("/enterpriseUser/select", { enterpriseCode: company.value }).then(() => {
        window.sessionStorage.removeItem("microRoutes");
        window.localStorage.removeItem("userInfo"); //清除了userInfo刷新后回自动拉取新数据
        this.setMicroRoutes();
        this.$router.push("/");
        location.reload();
        this.setActiveCompany(company);
      });
    },
    toSearchEngine(value) {
      window.open(value);
    },
    usersActions(val) {
      if (val == 1) {
        this.$router.push({ path: "/micro-platform/setuser" });
      } else if (val == 2) {
        this.$router.push({ path: "/micro-platform/setpassword" });
      } else if (val == 3) {
        this.$refs.logout.open();
      }
    },
    logout(safe, notoken, saveFullpath) {
      if (safe) {
        this.safeLoading = true;
      } else {
        this.loading = true;
      }
      mainMethods.logout(safe, notoken, saveFullpath).then(() => {
        this.safeLoading = this.loading = false;
        this.$refs.dialog.close();
      });
    },
    cancel() {
      this.$refs.logout.close();
    },
    toCompanyIntroduction() {
      this.$router.push({
        path: "/micro-platform/companyIntroduction",
      });
    },
  },
  mounted() {
    registerMicroServe();
    addGlobalUncaughtErrorHandler((event) => {});
  },
  beforeDestroy() {
    window.removeEventListener("viewDraft", this.viewDraft);
    window.removeEventListener("saveDraft", this.saveDraft);
    window.removeEventListener("showNetdisc", this.showNetdisc);
    window.removeEventListener("deleteDraft", this.deleteDraft);
    window.removeEventListener("changeLight", this.changeLight);
    window.removeEventListener("dispatchTask", this.dispatchTask);
    window.removeEventListener("openMaterialDetail", this.openMaterialDetail);
  },
  components: {
    Upload,
    Draft,
    AnDeng,
    Approval,
    Task,
    MaterialDetailDraw,
  },
};
</script>
<style lang="scss" scoped>
.company-dropdown {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #2878ff;
  z-index: 1000;
  img {
    width: 40px;
    height: 40px;
    margin: 10px;
    cursor: pointer;
  }
  .company-name {
    font-size: 12px;
    color: #fff;
    display: inline-block;
    overflow: hidden;
    text-overflow: clip;
    width: 48px;
    white-space: nowrap;
    cursor: pointer;
    & + i {
      color: #fff;
      font-size: 12px;
      transform: scale(0.7);
      display: inline-block;
      position: relative;
      top: -3px;
    }
  }
}
.nav-list {
  position: absolute;
  width: calc(100vw - 80px);
  height: calc(100vh - 20px);
  border-radius: 6px;
  box-shadow: 0 0 5px 2px #eee;
  background: rgb(255, 255, 255);
  z-index: 10000000;
  left: 70px;
  top: 10px;
  box-sizing: border-box;
  padding: 20px;
  padding-right: 0;
  .flex {
    display: flex;
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
}
.tool-wrapper {
  .title {
    display: none;
    width: 30px;
    margin-right: 20px;
    line-height: 36px;
    margin-left: 20px;
  }
}
.tools {
  height: 100%;
  .scroll {
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
    box-sizing: border-box;
  }
  .system {
    background-color: #fff;
    margin-bottom: 10px;
  }
  .system-name {
    font-size: 13px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 15px;
  }
  .menus {
    flex-wrap: wrap;
  }
  .menu {
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .system-menu-name {
    flex-shrink: 0;
    width: 69px;
    height: 36px;
    line-height: 36px;
    font-size: 13px;
    font-weight: 500;
    color: #444444;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .system-items {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 136px;
      height: 36px;
      padding: 8px 11px;
      box-sizing: border-box;
      background: #fafafa;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all ease-in 0.25s;
      &:hover {
        background: #ecf3fe;
      }
      cursor: pointer;
      .icon {
        margin-right: 10px;
        flex-shrink: 0;
      }
      .item-name {
        font-size: 13px;
        font-weight: 500;
        color: #111111;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .no-data {
    text-align: center;
    margin-top: 332px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      color: #999;
      font-size: 13px;
      margin-top: 28px;
    }
    img {
      display: block;
      width: 170px;
    }
  }
}
</style>
<style lang="scss" scoped>
.logo-wrapper {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  img {
    border-radius: 50%;
  }
}
.micro-main-app__container {
  display: flex;
}
.micro-main-app__content {
  flex: 1;
  width: 100%;
  height: calc(100vh - 30px);
  overflow-y: hidden;
}
.hb-nav-menu {
  flex-shrink: 0;
}
.hb-topbar {
  box-sizing: border-box;
}
.search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}
.full-search {
  ::v-deep .el-icon-arrow-up {
    display: none;
  }
}
.drop {
  width: 80px;
  cursor: pointer;
  background: #488af8;
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
}
</style>