<template>
  <div class="public-performance">
    <template v-if="isEdit">
      <el-form ref="form" size="mini" :model="formData" label-position="left" label-width="85px">
        <div class="flex-wrapper">
          <el-form-item style="width: 210px" label="绩效基数" prop="cardinality">
            <el-input type="number" v-model="formData.cardinality" clearable></el-input>
          </el-form-item>
          <el-form-item style="width: 210px" label="绩效比例" prop="ratio">
            <el-input type="number" v-model="formData.ratio" clearable></el-input>
          </el-form-item>
          <el-form-item style="width: 210px" label="绩效金额" prop="amount">
            <el-input type="number" v-model="formData.amount" clearable>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <div class="flex flex-end">
            <el-button size="mini" type="primary" plain @click="addReward">新增</el-button>
          </div>
        </div>

        <div
          :key="index"
          v-for="(item, index) in formData.rewardPunishmentList"
          class="flex-wrapper"
        >
          <el-form-item
            label="申请人"
            :prop="'rewardPunishmentList.' + index + '.proposer'"
            :rules="{
              required: true,
              message: '请选择',
            }"
          >
            <el-select v-model="item.proposer" filterable>
              <el-option
                v-for="item in $mainStore.state.usersList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="奖罚人"
            :prop="'rewardPunishmentList.' + index + '.rewardPunishmentOfficer'"
            :rules="{
              required: true,
              message: '请选择',
            }"
          >
            <el-select v-model="item.rewardPunishmentOfficer" filterable>
              <el-option
                v-for="item in $mainStore.state.usersList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="奖罚金额"
            :prop="'rewardPunishmentList.' + index + '.amount'"
            :rules="{
              required: true,
              message: '请输入数字',
            }"
          >
            <el-input type="number" v-model="item.amount" clearable>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item :prop="'rewardPunishmentList.' + index + '.ratio'">
            <span class="pl15" slot="label">比例</span>
            <el-input type="number" v-model="item.ratio" clearable>
              <template slot="append">%</template>
            </el-input>
          </el-form-item>

          <el-form-item
            style="width: 670px"
            label="奖罚原因"
            :prop="'rewardPunishmentList.' + index + '.reason'"
            :rules="{
              required: true,
              message: '奖罚原因不能为空',
            }"
          >
            <el-input v-model="item.reason" />
          </el-form-item>

          <i class="btn el-icon-remove-outline" @click="removeReward(index)"></i>
        </div>
      </el-form>
    </template>
    <template v-else>
      <hb-fragment
        v-layout-flex="{ minWidth: 280, nodeNum: 3, maxWidth: 280 }"
        :detailData="detailTop"
      ></hb-fragment>
      <hb-fragment
        v-for="(item, index) in formData.rewardPunishmentList"
        :key="index"
        v-layout-flex="{ minWidth: 280, nodeNum: 4, maxWidth: 280 }"
        :detailData="detailBottom(item)"
      ></hb-fragment>
    </template>
  </div>
</template>
<script>
export default {
  name: "PublicPerformance",
  data() {
    return {
      formData: {
        cardinality: "",
        ratio: "",
        amount: "",
        rewardPunishmentList: [],
      },
    };
  },
  computed: {
    detailTop() {
      const data = this.formData;
      return [
        {
          name: "绩效基数",
          value: data.cardinality,
          disabled: true,
        },
        {
          name: "绩效比例",
          value: data.ratio,
          disabled: true,
        },
        {
          name: "绩效金额",
          value: data.amount,
          disabled: true,
        },
      ];
    },
    detailBottom() {
      return (data) => {
        return [
          {
            name: "申请人",
            value: this.$mainStore.state.usersList?.find((i) => i.value == data.proposer)?.label,
            disabled: true,
          },
          {
            name: "奖罚人",
            value: this.$mainStore.state.usersList?.find(
              (i) => i.value == data.rewardPunishmentOfficer,
            )?.label,
            disabled: true,
          },
          {
            name: "奖罚金额",
            value: data.amount,
            disabled: true,
          },
          {
            name: "比例",
            value: data.ratio,
            disabled: true,
          },
          {
            name: "奖罚原因",
            value: data.reason,
            disabled: true,
          },
        ];
      };
    },
  },
  watch: {
    formData: {
      handler(newV) {
        Object.keys(this.formDataProp).forEach((key) => {
          this.formDataProp[key] = newV[key];
        });
      },
      deep: true,
    },
    // 算比例
    "formData.rewardPunishmentList": {
      handler() {
        if (this.formData.amount == 0) return;
        this.formData.rewardPunishmentList.forEach((i) => {
          i.amount && (i.ratio = ((i.amount / this.formData.amount) * 100)?.toFixed(2));
        });
      },
      deep: true,
    },
    // 算比例
    "formData.amount": {
      handler() {
        if (this.formData.amount == 0) return;
        this.formData.rewardPunishmentList.forEach((i) => {
          i.amount && (i.ratio = ((i.amount / this.formData.amount) * 100)?.toFixed(2));
        });
      },
    },
  },
  created() {
    // 在主应用传递当前组件给微应用的背景下，若直接使用传入的prop时会有响应式问题，在此将其赋值给data中的formData
    this.formData = this.formDataProp;
  },
  methods: {
    addReward() {
      console.log(this.formData, "v");
      this.formData.rewardPunishmentList.push({
        proposer: this.$mainStore.state.userInfo.userId,
        rewardPunishmentOfficer: "",
        amount: "",
        ratio: "",
        reason: "",
      });
    },
    removeReward(index) {
      this.formData.rewardPunishmentList.splice(index, 1);
    },
    validate() {
      let p1 = new Promise((resolve, reject) => {
        let total = this.formData.rewardPunishmentList
          .reduce((a, b) => {
            return a + Number(b.amount);
          }, 0)
          ?.toFixed(2);
        return Number(this.formData.amount) != Number(total)
          ? reject("绩效金额与各奖罚明细金额之和不相等，请检查！")
          : resolve();
      });
      let p2 = this.$refs.form
        .validate()
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject("绩效模块数据校验不通过，请检查！");
        });
      return Promise.all([p1, p2]);
    },
  },
  props: {
    formDataProp: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;

  ::v-deep .el-form-item {
    width: 210px;
    margin-right: 20px;
  }
}
.gloabel-reward {
  width: 100%;
}
.top {
  align-items: center;
}
.el-select {
  width: 100%;
}
.el-input-group {
  vertical-align: inherit !important;
}
.btn {
  cursor: pointer;
  font-size: 22px;
  color: #dfe3e9;
  line-height: 30px;
  &:hover {
    color: #488af8;
  }
}
.no-data {
  p {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #909399;
  }
}
</style>