<template>
  <div class="rich-comment-editor">
    <p class="comments-title mb10">
      <span><span v-if="showTitle">评论</span></span>
      <span
        :class="[fold ? 'icon-main-zhankai' : 'icon-main-shouqi', 'pointer', 'fold-btn']"
        @click="fold = !fold"
      >
        {{ fold ? "展开评论" : "折叠评论" }}
      </span>
    </p>
    <div class="editor-wrapper">
      <div
        v-html="content"
        ref="richEditor"
        class="rich-editor"
        contenteditable="true"
        @keyup.shift.50="mentionUser"
        @focus="handleSelectionChange"
        @click="handleSelectionChange"
      ></div>
      <div class="file-with-operation">
        <div class="file-list">
          <div
            class="file unit-item"
            @click="viewFile(item)"
            v-for="(item, index) in fileList"
            :key="index"
          >
            <div class="left">
              <HbSvg
                class="mr8"
                :iconClass="true | filterFileIcon(getSuffix(item.fileName))"
                fontSize="20px"
              ></HbSvg>
              <div class="title">
                {{ item.fileName }}
              </div>
            </div>
            <div class="right">
              <div class="icon">
                <i class="icon-main-xiazai mr10" @click.stop="downloadFile(item)"></i>
                <i class="icon-main-shanchu" @click.stop="remove(index, item)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="operation">
          <div class="flex-middle">
            <span class="icon-mention pointer" @click.stop="mentionUserByClick">@</span>
            <i title="上传附件" class="icon-annex icon-main-fujian pointer" @click="openFile"></i>
            <input class="hidden" type="file" multiple ref="input" @change="chooseFile" />
          </div>
          <div>
            <el-button size="mini" @click="clear">清空</el-button>
            <el-button size="mini" type="primary" :loading="loading" @click="send">发送</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 评论列表 -->
    <div class="comments-list" v-show="!fold">
      <ul>
        <li v-for="item in commentsListComputed" :key="item.id">
          <div class="top">
            <div class="left">
              <img :src="item.logo" />
              <span>{{ item.createUserName }}</span>
            </div>
            <div class="right">
              <span class="mr10">{{ item.createTime }}</span>
              <span
                :class="[
                  item.createId === $store.state.userInfo.userId ? '' : 'disabled',
                  'icon-main-shanchu',
                  'pointer',
                ]"
                @click="removeComment(item.id)"
              >
                删除评论
              </span>
            </div>
          </div>
          <div class="comment-content" v-html="item.commentContent"></div>
          <div class="file-list">
            <div
              class="file unit-item"
              @click="viewFile(i)"
              v-for="(i, index) in item.file"
              :key="index"
            >
              <div class="left">
                <HbSvg
                  class="mr8"
                  :iconClass="true | filterFileIcon(getSuffix(i.fileName))"
                  fontSize="20px"
                ></HbSvg>
                <div class="title">
                  {{ i.fileName }}
                </div>
              </div>
              <div class="right">
                <div class="icon">
                  <i class="icon-main-xiazai mr10" @click.stop="downloadFile(i)"></i>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="defaultShowSize && commentsList.length > Number(defaultShowSize)"
        style="height: 30px; line-height: 30px; text-align: center"
        :class="[commentFold ? 'icon-main-shouqi' : 'icon-main-zhankai', 'pointer', 'fold-btn']"
        @click="commentFold = !commentFold"
      >
        更多
      </div>
    </div>
    <hb-organize-dialog
      :append-to-body="true"
      :memberOnly="true"
      ref="organize"
      @confirm="confirmOrganize"
    />
  </div>
</template>

<script>
export default {
  name: "PublicComments",
  props: {
    baseURL: {
      type: String,
      default: "/platform",
    },
    //当eventId不是真正的单据，parentEventId则为真正的单据，后端会优先将这个字段设置为消息跳转的bussinessId
    parentEventId: {
      type: String,
      default: "",
    },
    //绑定到的单据Id
    eventId: {
      type: String,
      default: "",
    },
    module: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 140,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    content: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    // 默认显示几条评论，点击更多展开全部，默认是展示全部
    defaultShowSize: {
      type: String,
      default: "",
    },
    // 由于需求和商机的评论需要双向同步，因此需要调不同接口
    diffType: {
      type: String,
      default: "",
    },
    // 各系统评论 上传附件的url
    fileUploadUrl: {
      type: String,
      default: "/sysFiles/uploads",
    },
  },
  data() {
    return {
      loading: false,
      selection: null,
      currentRange: null,
      anchorOffset: 0,
      userInfo: [],
      fileList: [],
      commentsList: [],
      minHeight: 0,
      filesPadding: 50,
      fold: false,
      commentFold: false,
    };
  },
  filters: {
    filterFileIcon: function (isFile, fileType) {
      if (!isFile) {
        return "icon-color-wenjianjia";
      }
      const lowerFileType = fileType.toLowerCase();
      const fileMap = [
        {
          suffix: [".jpg", ".jpeg", ".png", ".gif"],
          icon: "icon-color-JPG",
        },
        {
          suffix: [".doc", ".docx"],
          icon: "icon-color-wendang",
        },
        {
          suffix: [".xls", ".xlsx"],
          icon: "icon-color-excel",
        },
        {
          suffix: ".pdf",
          icon: "icon-color-pdf",
        },
        {
          suffix: ".txt",
          icon: "icon-color-txt",
        },
      ];
      const iconClass = fileMap.find((item) => {
        return typeof item.suffix === "string"
          ? item.suffix === lowerFileType
          : item.suffix.includes(lowerFileType);
      })?.icon;
      return iconClass || "icon-color-qita1";
    },
  },
  computed: {
    // 计算需要展示多少条评论
    commentsListComputed() {
      let result = [];
      this.defaultShowSize
        ? (result = this.commentsList?.slice(0, Number(this.defaultShowSize)))
        : (result = this.commentsList);
      this.commentFold && (result = this.commentsList);
      return result;
    },
  },
  created() {
    this.minHeight = this.height;
    this.getCommentsList();
  },
  methods: {
    removeComment(id) {
      this.$confirm("请确认是否删除？")
        .then(() => {
          let api = "/approvalComment/deleteComment";
          this.diffType == "pmoNeed" && (api = "/approvalComment/deletePmoNeedComment");
          this.diffType == "bussiness" && (api = "/approvalComment/deletePmoNeedComment");
          this.$http
            .post(
              api,
              {
                ids: [id],
              },
              {
                baseURL: this.baseURL,
              },
            )
            .then((res) => {
              this.getCommentsList();
              this.$message.success("删除成功");
            });
        })
        .catch(() => {});
    },
    getCommentsList() {
      this.$http
        .post(`/approvalComment/commentList/${this.eventId}`, {}, { baseURL: this.baseURL })
        .then((res) => {
          this.commentsList = res || [];
        });
    },
    getSuffix(filename) {
      if (!filename) return "";
      let arr = filename.split(".");
      if (arr.length === 1) {
        return "";
      } else {
        return "." + arr.pop();
      }
    },
    openFile() {
      this.$refs.input.click();
    },
    chooseFile(ev) {
      let files = ev.target.files;
      let params = new FormData();
      files.forEach((item, index) => {
        params.append(`files[${index}]`, item);
      });
      this.$http.post(this.fileUploadUrl, params).then((res) => {
        this.$message.success("上传成功");
        this.fileList = [
          ...this.fileList,
          ...res.map((item) => ({
            fileName: item.fileName,
            fileType: "1",
            fileUrl: item.fileUrl,
            downUrl: item.downUrl,
            onlineUrl: item.onlineUrl,
            fileSize: item.fileSize,
          })),
        ];
      });
    },
    // 保留当前选择的范围
    handleSelectionChange() {
      const selection = window.getSelection && window.getSelection();
      if (selection && selection.rangeCount) {
        this.anchorOffset = selection.anchorOffset;
        this.focusNode = selection.focusNode;
        this.currentRange = selection.getRangeAt(0);
        this.selection = selection;
      }
    },
    mentionUser() {
      this.handleSelectionChange();
      this.removeMentionChar();
      this.$refs.richEditor.blur();
      this.openOrganize();
    },
    confirmOrganize({ localChecked }) {
      this.createMentionTag(localChecked);
      //不会自动关闭人员选择框需要手动调用
      this.$refs.organize.close();
    },
    mentionUserByClick() {
      this.$refs.richEditor.focus();
      this.anchorOffset++;
      this.openOrganize();
    },
    openOrganize() {
      this.$refs.organize.open();
      this.$refs.organize.init(this.$store.state.fullOrganizationData);
    },
    fileChange(file) {
      this.fileList = this.fileList.concat(file);
      console.log(this.fileList);
    },
    remove(index, { fileName }) {
      this.$confirm(`请确认是否删除附件:${fileName}`, "提示")
        .then(() => {
          this.fileList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 移除@符号
    async removeMentionChar() {
      const { selection } = this;
      selection.focusNode.nodeValue &&
        (selection.focusNode.nodeValue = selection.focusNode.nodeValue.replace(/[@?]/i, ""));
    },
    // 创建标签
    createMentionTag(userList) {
      let tag = "";
      userList.forEach((ele) => {
        tag += `<span class="link mr5 ml5" contenteditable="false" id="@${ele.userId}@">@${ele.name}</span>`;
      });
      this.pasteHtmlAtCaret(tag);
    },
    // 获取光标，插入html
    async pasteHtmlAtCaret(html) {
      let { selection, currentRange, focusNode, anchorOffset } = this;
      this.$refs.richEditor.focus();
      if (selection && selection.rangeCount === 0 && currentRange !== null) {
        selection.addRange(this.currentRange); // 保留光标在文字中间插入的最后位置
      }
      if (["", null, undefined].includes(currentRange)) {
        // eslint-disable-next-line no-debugger
        // 如果div没有光标，则在div内容末尾插入
        currentRange = await this.keepCursorEnd(true).getRangeAt(0);
      } else {
        // 对比range，检查光标是否在输入范围内
        const compareStart = currentRange.compareBoundaryPoints(Range.START_TO_START, currentRange);
        const compareEnd = currentRange.compareBoundaryPoints(Range.END_TO_END, currentRange);
        const compare = compareStart !== -1 && compareEnd !== 1;
        if (!compare) currentRange = await this.keepCursorEnd(true).getRangeAt(0);
      }
      currentRange.setStart(focusNode, anchorOffset - 1);
      currentRange.setEnd(focusNode, anchorOffset - 1);
      let tag = currentRange.createContextualFragment(html);
      let lastNode = tag.lastChild; // 记录插入tag之后的最后节点位置
      currentRange.insertNode(tag);
      if (lastNode) {
        // 如果有最后的节点
        currentRange.setStartAfter(lastNode);
        currentRange.collapse(false);
        selection.removeAllRanges();
        selection.addRange(currentRange);
      }
    },
    /**
     * 将光标重新定位到内容最后
     * isReturn 是否要将range实例返回
     * */
    keepCursorEnd(isReturn) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          // ie11 10 9 firefox safari
          this.$refs.richEditor.focus();
          this.selection.selectAllChildren(this.$refs.richEditor); // range 选择obj下所有子内容
          this.selection.collapseToEnd(); // 光标移至最后
          if (isReturn) resolve(this.selection);
        });
      });
    },
    // 获取当前选中
    getMentionTags() {
      const content = this.$refs.richEditor.innerHTML;
      let tags = content.match(/<span([\s\S]*?)@([\s\S]*?)@[^>]*>([\s\S]*?)<\/span>/g) || [];
      if (tags) {
        tags = tags.map((ele) => {
          const reg = /@([\s\S]*?)@/g;
          let uid = ele.match(reg) ? ele.match(reg)[0] : "";
          uid = uid.replace(/^[@?]/g, "").replace(/[@?]$/g, "");
          return uid;
        });
      }
      return tags;
    },
    viewFile(item) {
      window.open(item.onlineUrl);
    },
    // 发送
    send() {
      this.loading = true;
      let api = "/approvalComment/addComment";
      this.diffType == "pmoNeed" && (api = "/approvalComment/addPmoNeedComment");
      this.diffType == "bussiness" && (api = "/approvalComment/addBusinessComment");
      this.$http
        .post(
          api,
          {
            content: this.$refs.richEditor.innerHTML
              .replace(new RegExp("<[^>]+>", "gi"), "")
              .replace(/&nbsp;/gi, ""),
            commentContent: this.$refs.richEditor.innerHTML,
            eventId: this.eventId,
            parentEventId: this.parentEventId,
            file: this.fileList,
            userIds: [...new Set(this.getMentionTags())],
            title: this.title || "未命名单据",
            module: this.module,
          },
          { baseURL: this.baseURL },
        )
        .then((res) => {
          this.$message.success("评论成功");
          this.getCommentsList();
          this.clear();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clear() {
      this.$refs.richEditor.innerHTML = "";
      this.fileList = [];
    },
    downloadFile(item) {
      let downloadElement = document.createElement("a");
      downloadElement.href = item.downUrl;
      downloadElement.download = item.fileName; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(item.downUrl); // 释放掉blob对象
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-list {
  max-height: 800px;
  overflow: auto;
  margin-top: 10px;
  li {
    padding: 10px;
    background-color: #fafafa;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    font-size: 12px;
    .top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #e6e6e6;
      padding-bottom: 5px;
      color: #7a7a7a;

      .left {
        img {
          width: 28px;
          height: 28px;
          border-radius: 2px;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .right {
        span {
          font-size: 12px !important;
          &.pointer:hover {
            color: #488af8;
          }
          &.disabled {
            color: #ccc;
            pointer-events: none;
          }
        }
      }
    }
    .comment-content {
      padding: 10px 0;
    }
    .unit-item {
      background: #fff !important;
    }
  }
}
.rich-comment-editor {
  position: relative;
  .file-with-operation {
    .unit-item {
      margin-bottom: 10px;
    }
  }
  .comments-title {
    display: flex;
    justify-content: space-between;
  }
  .fold-btn {
    font-size: 14px !important;
    user-select: none;
    color: #7a7a7a;
    &:hover {
      color: #488af8;
    }
  }
  .editor-wrapper {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px;
  }
  .rich-editor {
    box-sizing: border-box;
    width: 100%;
    line-height: initial;
    outline: none;
    margin-bottom: 10px;
  }
  .file-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    .unit-item {
      width: 281px;
      height: 30px;
      border: 1px solid #eee;
      background: #fafafa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 8px;
      box-sizing: border-box;
      border-radius: 4px;
      .left {
        width: 210px;
        display: flex;
        .title {
          width: 175px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .right {
        i {
          font-size: 14px;
          cursor: pointer;
          color: #a2a7bc;
        }
      }
    }
  }
  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #488af8;
    user-select: none;
    .flex-middle {
      line-height: 24px;
    }
    .icon-mention {
      font-size: 16px;
      margin-right: 10px;
    }
    .icon-annex {
      font-size: 14px;
    }
  }
}
input.hidden {
  display: none;
}
</style>