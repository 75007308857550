<template>
  <div>
    <hb-dialog
      class="task-editor"
      ref="taskEditor"
      :title="`${title}任务`"
      width="1100px"
      :append-to-body="true"
      :destroy-on-close="true"
      @submit="submit"
      @closed="closed"
    >
      <template slot="normal">
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-position="left"
          label-width="105px"
        >
          <el-row
            class="form-row"
            :gutter="20"
            v-loading="loading"
            style="display: flex; flex-wrap: wrap; margin-bottom: 15px"
          >
            <el-col :span="24" v-if="formData.pName && title !== '复制'">
              <el-form-item label="父任务" prop="pName" class="pName-box">
                <span>{{ formData.pName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="任务目标" prop="title">
                <el-input
                  v-model="formData.title"
                  class="task-name__input"
                  type="text"
                  placeholder="请输入任务目标"
                  maxlength="100"
                  show-word-limit
                  :disabled="formData.status === 'hand' || taskStatusComputed"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="priority">
                <span slot="label">
                  优先级
                  <el-popover placement="right" width="125" trigger="hover">
                    3 插队加急处理
                    <br />
                    2 正常处理
                    <br />
                    1 承诺时间做完即可
                    <i
                      slot="reference"
                      class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                    ></i>
                  </el-popover>
                </span>
                <el-select
                  v-model="formData.priority"
                  class="suffix"
                  placeholder="优先级"
                  :disabled="formData.status === 'hand' || taskStatusComputed"
                >
                  <el-option value="3" label="3"></el-option>
                  <el-option value="2" label="2"></el-option>
                  <el-option value="1" label="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="任务类型" prop="taskType">
                <el-select
                  v-model="formData.taskType"
                  :disabled="!!taskTypeProp"
                  @change="changeTaskType"
                >
                  <el-option
                    v-for="(item, index) in taskTypeList"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关联单据" prop="document">
                <!-- 按灯的关联单据下拉会有很长很长的数据，这里给按灯的下拉宽度定死，给文字提示 -->
                <el-select
                  :popper-class="formData.taskType == 1 ? 'custom-document-select' : ''"
                  v-model="formData.document"
                  :disabled="!!taskTypeProp || formData.taskType === '3'"
                  filterable
                  clearable
                  remote
                  placeholder="请直接搜索"
                  :remote-method="(v) => getPmoOrCommercialOpportunity(v)"
                >
                  <div class="tip-font">请直接搜索</div>
                  <el-option
                    v-for="item in pillOptions"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                    <el-tooltip placement="top" v-if="formData.taskType == 1">
                      <div slot="content">
                        <span>{{ item.label }}</span>
                      </div>
                      <span class="iclass-text-ellipsis">{{ item.label }}</span>
                    </el-tooltip>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="任务类别" prop="taskCategory">
                <el-select
                  :disabled="formData.taskType != '4'"
                  v-model="formData.taskCategory"
                  @change="createName"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in taskCategoryList"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="difficulty">
                <span slot="label">
                  任务难度
                  <el-popover placement="right" width="170" trigger="hover">
                    5 非常难
                    <br />
                    4 比较难
                    <br />
                    3 一般
                    <br />
                    2 比较容易
                    <br />
                    1 非常容易
                    <i
                      slot="reference"
                      class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                    ></i>
                  </el-popover>
                </span>
                <el-select
                  placeholder="请选择任务难度"
                  v-model="formData.difficulty"
                  :disabled="taskStatusComputed"
                >
                  <el-option
                    v-for="item in difficultyOpt"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="发包人" prop="createId">
                <el-select
                  v-model="formData.createId"
                  :disabled="taskStatusComputed"
                  :clearable="false"
                  @change="setcreateUserName"
                  filterable
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userId"
                    :value="item.userId"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="发包产品线" prop="createProductLineId">
                <el-select
                  :clearable="false"
                  placeholder="请选择"
                  v-model="formData.createProductLineId"
                  :disabled="taskStatusComputed"
                  filterable
                >
                  <el-option
                    v-for="item in productLines"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="承包人" prop="contractor">
                <el-select
                  v-model="formData.contractor"
                  @change="setContractorName"
                  :disabled="taskStatusComputed"
                  filterable
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userId"
                    :value="item.userId"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="辅助PM" prop="assistPmId">
                <el-select
                  v-model="formData.assistPmId"
                  :disabled="taskStatusComputed"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userId"
                    :value="item.userId"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="部门" prop="responsibleDept">
                <el-cascader
                  ref="cascader"
                  placeholder="请选择部门"
                  :show-all-levels="false"
                  v-model="formData.responsibleDept"
                  :options="deptTree"
                  :props="{
                    checkStrictly: true,
                    emitPath: false,
                  }"
                  changeBlur
                  clearable
                  filterable
                  :disabled="taskStatusComputed"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="承包产品线" prop="productLineId">
                <el-select
                  placeholder="请选择"
                  v-model="formData.productLineId"
                  :disabled="taskStatusComputed"
                  filterable
                >
                  <el-option
                    v-for="item in productLines"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="区域中心" prop="areaId">
                <el-select
                  placeholder="请选择区域中心"
                  v-model="formData.areaId"
                  :disabled="taskStatusComputed"
                  filterable
                >
                  <el-option
                    v-for="item in $store.getters.areaCenterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="期望交付" prop="plannedDeliveryTime">
                <el-date-picker
                  v-model="formData.plannedDeliveryTime"
                  type="date"
                  placeholder="请选择日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="taskStatusComputed"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <!-- 非必填 -->

            <el-col :span="6">
              <el-form-item label="计划开始" prop="expectedStartTime">
                <el-date-picker
                  v-model="formData.expectedStartTime"
                  type="date"
                  placeholder="请选择日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="formData.status === 'hand' || taskStatusComputed"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item prop="delayDays">
                <span slot="label">
                  延误天数
                  <el-popover placement="right" width="250" trigger="hover">
                    <p>计算规则:</p>
                    <br />
                    有实际交付时间，则延误天数 = 实际交付时间 - 承诺交付时间；
                    <br />
                    没有实际交付时间，则延期天数 = 当前时间 - 承诺交付时间；
                    <i
                      slot="reference"
                      class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                    ></i>
                  </el-popover>
                </span>
                <el-input disabled v-model="formData.delayDays"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="延误扣除" prop="delayDeduct">
                <el-input v-model="formData.delayDeduct"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="验收方式" prop="checkingWay">
                <el-select
                  placeholder="请选择验收方式"
                  v-model="formData.checkingWay"
                  :disabled="taskStatusComputed"
                  clearable
                >
                  <el-option
                    v-for="item in checkingWayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="['客户验收', '下游研发验收'].includes(formData.checkingWay)">
              <el-form-item label="验收人" prop="permitChecker">
                <el-select
                  v-model="formData.permitChecker"
                  :disabled="taskStatusComputed"
                  multiple
                  clearable
                  collapse-tags
                  filterable
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userId"
                    :value="item.userId"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="light">
                <span slot="label">
                  按灯
                  <el-popover placement="right" width="340" trigger="hover">
                    <p>计算规则：</p>
                    <br />
                    <p>1、待开始的任务：</p>
                    <p>当前时间-发包时间 &lt; 48h，绿灯</p>
                    <p>当前时间-发包时间 &gt; 48h，黄灯</p>
                    <p>当前时间-发包时间 &gt; 72h，红灯</p>
                    <br />
                    <p>2、进行中的任务：</p>
                    <p>当前时间-承诺交付时间 ≤ 0天，绿灯</p>
                    <p>0天 &lt; 当前时间-承诺交付时间 ≤ 7天，黄灯</p>
                    <p>当前时间-承诺完成时间 &gt; 7天，红灯</p>
                    <br />
                    <p>3、已完成的任务：按第二点结果</p>

                    <br />
                    <i>任务完成后，用户可在列表页手动修改以覆盖计算的值</i>
                    <i
                      slot="reference"
                      class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                    ></i>
                  </el-popover>
                </span>
                <el-select disabled v-model="formData.light">
                  <el-option
                    v-for="item in $store.getters.andengOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="按灯说明">
                <el-input
                  placeholder="按灯说明"
                  v-model="formData.lightExplain"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="任务描述" style="position: relative">
                <div
                  id="taskIntroEditor"
                  ref="taskIntroEditor"
                  :style="{ 'pointer-events': taskStatusComputed ? 'none' : 'auto' }"
                  class="rich-editor"
                ></div>
                <div class="editorMask" v-if="taskStatusComputed"></div>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="验收标准">
                <el-input
                  placeholder="验收标准"
                  v-model="formData.acceptanceStandard"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  :disabled="taskStatusComputed"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item
                style="width: 100%"
                v-for="(item, index) in formData.needHelp"
                :key="index + 'needHelp'"
                label="我需要的帮助"
              >
                <div class="flex" style="margin-top: 4px; align-items: center">
                  <el-input
                    placeholder="请输入我需要的帮助"
                    type="textarea"
                    :autosize="{ minRows: 1 }"
                    v-model="item.content"
                    :disabled="taskStatusComputed"
                  ></el-input>
                  <el-button
                    class="ml10"
                    @click="addItem('needHelp', index)"
                    :disabled="taskStatusComputed"
                  >
                    新增
                  </el-button>
                  <el-button
                    @click="removeItem('needHelp', index)"
                    :disabled="formData.needHelp.length == 1 || taskStatusComputed"
                  >
                    删除
                  </el-button>
                </div>
                <div>
                  <span class="mr10" v-if="item.modifyName">更新人：{{ item.modifyName }}</span>
                  <span v-if="item.modifyTime">更新时间：{{ item.modifyTime }}</span>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item
                style="width: 100%"
                v-for="(item, index) in formData.keyResult"
                :key="index + 'keyResult'"
                label="输出结果"
              >
                <div class="flex" style="margin-top: 4px; align-items: center">
                  <el-input
                    placeholder="请输入输出结果"
                    type="textarea"
                    :autosize="{ minRows: 1 }"
                    v-model="item.content"
                    :disabled="taskStatusComputed"
                  ></el-input>
                  <el-button
                    class="ml10"
                    @click="addItem('keyResult', index)"
                    :disabled="taskStatusComputed"
                  >
                    新增
                  </el-button>
                  <el-button
                    @click="removeItem('keyResult', index)"
                    :disabled="formData.keyResult.length == 1 || taskStatusComputed"
                  >
                    删除
                  </el-button>
                </div>
                <div>
                  <span class="mr10" v-if="item.modifyName">更新人：{{ item.modifyName }}</span>
                  <span v-if="item.modifyTime">更新时间：{{ item.modifyTime }}</span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item class="form-item__flex" label="抄送人员">
                <div
                  class="staff-list"
                  :style="{ 'pointer-events': taskStatusComputed ? 'none' : 'auto' }"
                >
                  <div class="staff-item" v-for="(item, index) in formData.copys" :key="index">
                    <div class="avatar">
                      <i
                        class="icon-main-a-quxiaochacha icon-close"
                        @click="removeCarbonCopies(index)"
                        v-if="!taskStatusComputed"
                      ></i>
                      <img
                        class="header"
                        :src="item.headImage || require('@/assets/images/defaultAvatar.png')"
                      />
                    </div>
                    <div class="username">{{ item.name }}</div>
                  </div>
                  <i
                    v-if="!addUserBtnLoading"
                    class="icon-main-tianjiarenyuan icon-add-user pointer"
                    @click="openOrganize"
                  ></i>
                  <div v-else class="add-staff">
                    <i class="el-icon-loading"></i>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件" class="mb0">
                <div slot="label" class="custom-label">
                  <span class="custom-label__main">附件</span>
                </div>

                <HbUpload
                  class="file-unit-wrapper"
                  :list="formData.files"
                  :editAble="!taskStatusComputed"
                />
              </el-form-item>
            </el-col>
            <template v-if="eventId">
              <PublicReward ref="rewardForm" :formData="formData" class="mt15 pl10 pr10" isDialog />
            </template>
          </el-row>
        </el-form>
      </template>
      <template slot="dialogFooter">
        <el-button size="small" @click="closedBtn">取消</el-button>
        <el-button type="primary" size="small" :loading="confirmLoading" @click="submit">
          确定
        </el-button>
      </template>
    </hb-dialog>
    <hb-organize-dialog
      :append-to-body="true"
      ref="organize"
      memberOnly
      @confirm="confirmOrganize"
    />
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import RichEditor from "wangeditor";
import PublicReward from "../../publicComponents/publicReward.vue";
const defaultFormData = {
  id: "",
  pid: "",
  pName: "",
  code: "",
  taskCategoryName: "",
  taskType: "3",
  document: "",
  documentName: "",
  responsibleDept: "",
  responsibleDeptName: "",
  createId: "",
  createUserName: "",
  productLineId: "",
  productLineName: "",
  title: "",
  priority: "2",
  contractor: "",
  contractorName: "",
  createTime: "",
  statusName: "",
  actualStartTime: "",
  actualFinishTime: "",
  status: "",
  description: "",
  estimatedTime: "",
  expectedStartTime: moment().format("yyyy-MM-DD HH:mm:ss"),
  files: [],
  taskCategory: "",
  light: "绿灯",
  lightExplain: "",
  delayDays: "",
  delayDeduct: "",
  realityDeliveryTime: "",
  plannedDeliveryTime: "",
  difficulty: "3",
  assistPmId: "",
  assistPmName: "",
  areaId: "",
  checkingWay: "发包人验收",
  createProductLineId: "",
  createProductLineName: "",
  acceptanceStandard: "",
  copys: [],
  permitChecker: [],
  rewardPunishmentList: [],
  keyResult: [{ content: "", field: "keyResult" }],
  needHelp: [{ content: "", field: "needHelp" }],
  child: [],
  rewardPunishmentAmount: "",
  editedTime: "", // 用于在任务清单中表示该任务数据是否被编辑过，判断是否是预编辑操作
};
import { cloneDeep } from "lodash";
export default {
  name: "taskEditor",
  components: {
    PublicReward,
  },
  data() {
    return {
      title: "",
      dialogType: "", // 弹窗类型
      loading: false,
      addUserBtnLoading: false,
      taskCategoryList: [],
      formData: cloneDeep(defaultFormData),
      fileList: [],
      pillOptions: [],
      difficultyOpt: ["5", "4", "3", "2", "1"],
      taskTypeList: [
        { label: "普通任务", value: "3" },
        { label: "项目", value: "4" },
        { label: "需求", value: "6" },
        { label: "技术工艺瓶颈", value: "7" },
        { label: "商机任务", value: "5" },
        { label: "按灯", value: "1" },
        { label: "施工单", value: "8" },
        { label: "客户订单", value: "9" },
        { label: "生产订单", value: "10" },
        { label: "内协单", value: "11" },
        { label: "采购单", value: "12" },
        { label: "IOT工单", value: "2", disabled: true },
      ],
      taskIntroEditor: null,
      checkingWayList: [
        { label: "发包人验收", value: "发包人验收" },
        { label: "承包人验收", value: "承包人验收" },
        { label: "客户验收", value: "客户验收" },
        { label: "下游研发验收", value: "下游研发验收" },
      ],
      eventId: "", // 任务id
      taskTypeProp: "",
      confirmLoading: false,
      componentName: "",
    };
  },
  computed: {
    userList() {
      return this.$store.state.usersList;
    },
    deptTree() {
      return this.$store.getters.deptOptions[0]?.children;
    },
    productLines() {
      return this.$store.getters.productLineOptions;
    },
    rules() {
      return {
        priority: [{ required: true, message: "优先级不能为空" }],
        taskType: [{ required: true, message: "任务类型不能为空" }],
        document: [
          {
            required: this.documentRequired,
            message: "关联单据不能为空",
          },
        ],
        productLineId: [{ required: true, message: "承包产品线不能为空", trigger: "change" }],
        contractor: [{ required: true, message: "承包人不能为空" }],
        responsibleDept: [{ required: true, message: "部门不能为空", trigger: "change" }],
        areaId: [{ required: true, message: "区域中心不能为空", trigger: "change" }],
        title: [{ required: true, message: "任务目标不能为空" }],
        plannedDeliveryTime: [{ required: true, message: "期望交付时间不能为空" }],
        difficulty: [{ required: true, message: "任务难度不能为空" }],
        createId: [{ required: true, message: "发包人不能为空" }],
        createProductLineId: [{ required: true, message: "发包产品线不能为空" }],
        light: [{ required: true, message: "按灯不能为空" }],
        permitChecker: [{ type: "array", required: true, message: "验收人不能为空" }],
      };
    },
    // 要在任务模块中使用，并且任务类型不为普通任务时 关联单据必填
    documentRequired() {
      return !["2", "3"].includes(this.formData.taskType) && !this.taskTypeProp;
    },
    // 对状态为完成待验收、已验收关闭、失败关闭、已拒绝、发包人关闭的数据，编辑按钮也可点击，编辑页面仅支持修改：任务类型、关联单据、任务类别、按灯说明、奖罚数据
    taskStatusComputed() {
      return (
        ["completed", "closedAcceptance", "closedFail", "refuse", "closedCreateUser"].includes(
          this.formData.status,
        ) && this.dialogType != "copy"
      );
    },
  },
  methods: {
    /**
     * type   弹窗模式  -- 新增、编辑、复制、预编辑
     * detail     任务id 或者  任务整条数据
     * taskTypeProp  在非任务页面中传入的任务类型
     * documentId    在非任务页面中传入的关联单据id
     * componentName   任务清单可能会存在一个页面多个组件都在使用，因此需要用组件名称区分
     */
    open({ type, detail, taskTypeProp, documentId, componentName }) {
      this.componentName = componentName; // 用于解决在同一个页面存在多个任务清单组件的事件冲突问题
      console.log(type, detail, taskTypeProp, documentId, "编辑弹窗打开");
      if (taskTypeProp) {
        this.taskTypeProp = taskTypeProp;
        this.formData.taskType = taskTypeProp;
        this.formData.document = documentId;
      }

      this.$refs.taskEditor.open();
      this.loading = true;
      this.initSelectData();
      this.$nextTick(() => {
        this.taskIntroEditor = this.initEditor("taskIntroEditor");
      });
      this.dialogType = type;

      switch (type) {
        case "add":
          this.title = "新增";
          // 发包人和发包产品线默认值
          this.formData.createId = this.$store.state.userInfo.userId;
          this.formData.createUserName = this.$store.state.userInfo.name;
          this.$http
            .get("/user/findProductLineByUser", {
              params: { userId: this.$store.state.userInfo.userId },
            })
            .then((res) => {
              this.formData.createProductLineId = res.productLineId || "";
              this.formData.createProductLineName = res.productLineName || "";
            });
          break;
        case "edit":
          this.title = "编辑";
          this.eventId = detail; // 不用这样的写法，子任务的富文本编辑器就会挂载不到元素上
          this.getDetailData(detail);
          break;
        case "copy":
          this.title = "复制";
          this.formData.status = "";
          this.getDetailData(detail);
          break;
        // 前端预编辑
        case "fontEdit":
          this.title = "编辑";
          this.eventId = detail.id;
          this.packageFormData(detail);
          break;
        default:
          break;
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 获取任务详情
    getDetailData(id) {
      this.$http
        .get(`/task/detail/${id}`)
        .then((res) => {
          this.packageFormData(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 包装表单数据
    packageFormData(data) {
      Object.keys(defaultFormData).forEach((key) => {
        // 计划开始有默认值且非必填，所以先这样做
        if (key == "expectedStartTime") {
          this.formData[key] = data[key];
          return;
        }
        data[key] && (this.formData[key] = data[key]);
      });
      this.fixDetailData(this.formData, ["needHelp", "keyResult"]);
      this.$nextTick(() => {
        this.taskIntroEditor.txt.html(data.description);
      });
      !this.taskTypeProp && this.getPmoOrCommercialOpportunity(data.documentName?.split(" ")[0]); // 关联单据下拉
    },
    //字段可添加多条信息需要修正数据
    fixDetailData(data, arr = []) {
      arr.forEach((i) => {
        if (!data[i].length) {
          data[i].push({
            content: "",
            field: i,
          });
        }
      });
    },
    // 初始化下拉
    initSelectData() {
      let ps = [this.$http.get("/dictSysItem/PmoList", { params: { pCode: "TASK_NAME" } })];
      // 若在非任务页面使用时在此获取关联单据下拉
      if (this.taskTypeProp) {
        ps.push(
          this.$http.get("/task/taskDropdownByTaskType", {
            params: { taskType: this.taskTypeProp },
          }),
        );
      }
      Promise.all(ps)
        .then((res) => {
          const [taskCategoryList, pillOptions] = res;
          this.taskCategoryList = taskCategoryList.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          this.pillOptions = pillOptions || [];
        })
        .finally(() => {
          if (this.dialogType == "add" || this.dialogType == "fontEdit") this.loading = false;
        });
    },
    submit() {
      console.log("submit");
      let p1 = this.$refs.rewardForm?.validate();
      let p2 = this.$refs.form.validate();
      Promise.all([p1, p2])
        .then(() => {
          this.confirmLoading = true;
          this.formData.description = this.taskIntroEditor.txt.html();
          // 若在其他单据中关联任务
          if (this.taskTypeProp) {
            // 预添加反显到任务清单列表的字段
            this.formData.createProductLineName = this.productLines.find(
              (i) => i.value == this.formData.createProductLineId,
            )?.label;
            this.formData.documentName = this.pillOptions.find(
              (i) => i.value == this.formData.document,
            )?.label;
            this.formData.responsibleDeptName = this.$refs.cascader.getCheckedNodes()[0]?.label;
            this.formData.productLineName = this.productLines.find(
              (i) => i.value == this.formData.productLineId,
            ).label;
            this.formData.areaName = this.$store.getters.areaCenterOptions.find(
              (i) => i.value == this.formData.areaId,
            )?.label;
            this.formData.assistPmName = this.$store.state.usersList.find(
              (i) => i.value == this.formData.assistPmId,
            )?.label;
            this.formData.rewardPunishmentAmount = this.formData.rewardPunishmentList.reduce(
              (a, b) => {
                return a + Number(b.amount);
              },
              0,
            );
            // 刷新任务清单列表组件
            this.$emit("refreshTaskList", {
              dialogType: this.dialogType,
              formData: { ...cloneDeep(this.formData) },
              componentName: this.componentName,
            });
            this.$emit("refresh");
            this.sleepClosed();
          } else {
            const url = this.dialogType == "edit" ? "/task/update" : "/task/add";
            const formData = cloneDeep(this.formData);
            formData.copys = formData.copys.map((item) => item.id);
            // 如果是商机或订单任务  就把关联单据下拉中返回的customerId加到参数中 —— 任务列表的跳转商机或订单详情路由需要
            if (["5", "9"].includes(formData.taskType)) {
              formData.customerId = this.pillOptions.find(
                (i) => i.value == formData.document,
              )?.customerId;
            }
            this.$http
              .post(url, formData)
              .then((res) => {
                this.$message.success("操作成功");
                this.$emit("refresh");
              })
              .finally(() => {
                this.closed();
              });
          }
        })
        .catch((err) => {
          this.$message.error(err || "基础信息校验不通过，请检查！");
        });
    },
    // 不调用接口时，使用睡眠函数等500毫秒再关掉按钮loading和弹窗，防止提交按钮暴力点击
    async sleepClosed() {
      const sleep = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500);
        });
      };
      await sleep();
      this.closed();
    },
    closedBtn() {
      this.$refs.taskEditor?.close();
    },
    closed() {
      console.log("closed");
      this.formData = cloneDeep(defaultFormData);
      this.$nextTick(() => {
        this.confirmLoading = false;
        this.$refs.form.clearValidate();
      });
      this.eventId = "";
      this.taskTypeProp = "";
      this.taskIntroEditor?.destroy();
      this.taskIntroEditor = null;
      this.$refs.taskEditor.close();
    },

    addItem(key, index) {
      this.formData[key].splice(index + 1, 0, {
        content: "",
        field: key,
      });
    },
    removeItem(key, index) {
      this.formData[key].splice(index, 1);
    },

    removeReward(index) {
      this.formData.rewardPunishmentList.splice(index, 1);
    },
    addReward() {
      this.formData.rewardPunishmentList.push({
        proposer: this.$store.state.userInfo.userId,
        rewardPunishmentOfficer: this.formData.contractor,
        reason: "",
      });
    },
    // 非普通任务时关联单据下拉数据
    getPmoOrCommercialOpportunity: debounce(function (keyword) {
      if (!this.formData.taskType || !keyword) return;
      this.$http
        .get("/task/taskDropdownByTaskType", {
          params: { taskType: this.formData.taskType, keyword },
        })
        .then((res) => {
          this.pillOptions = res;
        });
    }, 200),
    changeTaskType(value) {
      if (value === "3") {
        this.formData.document = this.formData.taskCategory = "";
      }
      if (!["2", "3"].includes(value)) {
        this.pillOptions = []; // 清空关联单据下拉，用户直接搜索
      }
      this.$refs.taskEditor.clearValidate();
    },
    initEditor(element) {
      const richEditor = new RichEditor(`#${element}`);
      richEditor.config.menus = ["link", "image"];
      richEditor.config.height = 120;
      richEditor.config.zIndex = 500;
      richEditor.config.uploadImgMaxLength = 3;
      // 取消自动 focus
      richEditor.config.focus = false;
      richEditor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        resultFiles.forEach((item) => {
          let file = new FormData();
          file.append("file", item);
          this.$http.post("/file/upload", file).then((res) => {
            insertImgFn(res.downloadUrl);
          });
        });
      };
      richEditor.config.onblur = () => {
        this.$refs[element].classList.remove("active");
      };
      richEditor.config.onfocus = () => {
        this.$refs[element].classList.add("active");
      };
      richEditor.create();
      return richEditor;
    },
    // 发包人下拉change
    setcreateUserName(value) {
      this.formData.createUserName = this.userList.find((i) => i.userId === value)?.name;
      this.formData.createProductLineId = "";
      if (value) {
        //获取所属发包产品线
        this.$http.get("/user/findProductLineByUser", { params: { userId: value } }).then((res) => {
          this.formData.createProductLineId = res.productLineId || "";
        });
      }
    },
    // 承包人下拉change
    setContractorName(value) {
      this.formData.contractorName =
        this.formData.responsibleDept =
        this.formData.areaId =
        this.formData.productLineId =
          "";
      if (value) {
        this.formData.contractorName = this.userList.find((i) => i.userId === value).name;
        const ps = [
          this.$http.get("/user/findDeptAndAreaByUser", {
            params: { userId: value },
          }),
          this.$http.get("/user/findProductLineByUser", { params: { userId: value } }),
          this.$http.get("/user/hrWorkUser/getHrWorkUser", { params: { userId: value, type: 1 } }), // 承包人上级
        ];
        Promise.all(ps).then((res) => {
          const [deptAreaObj, productLineObj, copys] = res;
          this.formData.responsibleDept = deptAreaObj.deptId || "";
          this.formData.areaId = deptAreaObj.areaId || "";
          this.formData.productLineId = productLineObj.productLineId || "";
          this.formData.copys = copys || [];
        });
      }
    },
    createName(value) {
      if (!value) {
        this.formData.taskCategoryName = "";
        return;
      }
      this.formData.taskCategoryName = this.taskCategoryList.find((i) => i.value === value).label;
    },
    removeCarbonCopies(index) {
      this.formData.copys.splice(index, 1);
    },
    openOrganize() {
      if (this.addUserBtnLoading) return;
      this.addUserBtnLoading = true;
      const rewriteUserList = this.formData.copys.map((item) => ({
        ...item,
        isDept: "0",
      }));
      this.$refs.organize.open();
      this.$refs.organize.init(this.$store.state.fullOrganizationData, rewriteUserList);
      this.addUserBtnLoading = false;
    },
    confirmOrganize({ localChecked, originChecked }) {
      const copys = [...originChecked, ...localChecked];
      this.formData.copys = copys.map((item) => ({
        ...item,
        headImage: item.logo || item.headImage,
      }));
      this.$refs.organize.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex; // 有些系统同类名样式会覆盖
}
.tip-font {
  font-size: 12px;
  color: rgb(165, 165, 165);
  text-align: center;
  margin-bottom: 3px;
}
::v-deep .el-select,
.el-date-editor,
.el-cascader {
  width: 100% !important;
}
::v-deep .el-input-group {
  vertical-align: middle;
}
.el-dialog__wrapper {
  .form-item-module {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .form-row {
      border-bottom: 1px solid #eee;
    }
  }
  .expense-desc {
    font-size: 12px;
    color: #ff5a5a;
  }
  .custom-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &__main {
      font-size: 13px;
      color: #444;
      margin-right: 16px;
    }
    &__desc {
      font-size: 12px;
      color: #999;
    }
  }
  .choose-btn {
    color: #488af8;
    cursor: pointer;
    font-size: 12px;
    i {
      font-size: 12px;
    }
  }
  .file-item {
    width: 100%;
    margin-top: 10px;
  }
  ::v-deep.global-upload {
    .trigger {
      display: inline-block;
    }
  }
  .input-with-widget {
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    ::v-deep.el-input,
    .el-select {
      &.suffix {
        width: 120px;
        &::after {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          width: 1px;
          height: 20px;
          background-color: #dbdbdb;
        }
      }
      .el-input__inner {
        border: none;
        height: 28px;
      }
    }
  }
  .task-name__input {
    ::v-deep.el-input__inner {
      padding-right: 78px;
    }
  }
  .editorMask {
    background: rgba(245, 247, 250, 0.71);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50000;
  }
  ::v-deep.rich-editor {
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #dbdbdb;
    line-height: initial;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #111;
    .w-e-toolbar,
    .w-e-text-container,
    .w-e-menu-panel {
      border: none !important;
      p {
        font-size: 13px !important;
      }
      .w-e-menu {
        height: 30px;
        line-height: 30px;
      }
    }
    .w-e-toolbar {
      border-bottom: 1px solid #dbdbdb !important;
    }
    &.active {
      border-color: #409eff;
    }
  }
  .staff-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 26px;
    @supports not (gap: 20px 26px) {
      margin-top: -20px;
      margin-right: -26px;
      .staff-item {
        margin-top: 20px;
        margin-right: 26px;
      }
    }
    .staff-item-with-arrow {
      display: flex;
      align-items: center;
      line-height: 60px;
      .icon-arrow {
        margin-right: 26px;
        color: #488af8;
      }
    }
    .staff-item {
      position: relative;
      box-sizing: border-box;
      line-height: initial;
      .avatar {
        position: relative;
        width: 22px;
        height: 22px;
        margin: 0 auto;
        .icon-close {
          position: absolute;
          right: -6px;
          top: -6px;
          width: 14px;
          height: 14px;
          background: #f5f7fa;
          color: #9c9c9c;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 6px;
          cursor: pointer;
          &::before {
            transform: scale(0.6);
          }
        }
      }
      .username {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 500;
        color: #444;
      }
      .header {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .icon-add-user {
      font-size: 22px;
    }
    .add-staff {
      width: 22px;
      height: 22px;
      border: 1px dashed #cccccc;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    .chosen-approver {
      .icon-arrow {
        opacity: 0;
      }
    }
  }
  .iclass-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pName-box {
    ::v-deep .el-form-item__label {
      padding-left: 30px !important;
    }
  }
}
</style>
<style>
/* 如果要设置select下拉区域的宽度，就不能加scoped 否则不生效 */
.custom-document-select {
  width: 450px;
}
</style>