var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"public-performance"},[(_vm.isEdit)?[_c('el-form',{ref:"form",attrs:{"size":"mini","model":_vm.formData,"label-position":"left","label-width":"85px"}},[_c('div',{staticClass:"flex-wrapper"},[_c('el-form-item',{staticStyle:{"width":"210px"},attrs:{"label":"绩效基数","prop":"cardinality"}},[_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(_vm.formData.cardinality),callback:function ($$v) {_vm.$set(_vm.formData, "cardinality", $$v)},expression:"formData.cardinality"}})],1),_c('el-form-item',{staticStyle:{"width":"210px"},attrs:{"label":"绩效比例","prop":"ratio"}},[_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(_vm.formData.ratio),callback:function ($$v) {_vm.$set(_vm.formData, "ratio", $$v)},expression:"formData.ratio"}})],1),_c('el-form-item',{staticStyle:{"width":"210px"},attrs:{"label":"绩效金额","prop":"amount"}},[_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('div',{staticClass:"flex flex-end"},[_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.addReward}},[_vm._v("新增")])],1)],1),_vm._l((_vm.formData.rewardPunishmentList),function(item,index){return _c('div',{key:index,staticClass:"flex-wrapper"},[_c('el-form-item',{attrs:{"label":"申请人","prop":'rewardPunishmentList.' + index + '.proposer',"rules":{
            required: true,
            message: '请选择',
          }}},[_c('el-select',{attrs:{"filterable":""},model:{value:(item.proposer),callback:function ($$v) {_vm.$set(item, "proposer", $$v)},expression:"item.proposer"}},_vm._l((_vm.$mainStore.state.usersList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"奖罚人","prop":'rewardPunishmentList.' + index + '.rewardPunishmentOfficer',"rules":{
            required: true,
            message: '请选择',
          }}},[_c('el-select',{attrs:{"filterable":""},model:{value:(item.rewardPunishmentOfficer),callback:function ($$v) {_vm.$set(item, "rewardPunishmentOfficer", $$v)},expression:"item.rewardPunishmentOfficer"}},_vm._l((_vm.$mainStore.state.usersList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"奖罚金额","prop":'rewardPunishmentList.' + index + '.amount',"rules":{
            required: true,
            message: '请输入数字',
          }}},[_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"prop":'rewardPunishmentList.' + index + '.ratio'}},[_c('span',{staticClass:"pl15",attrs:{"slot":"label"},slot:"label"},[_vm._v("比例")]),_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(item.ratio),callback:function ($$v) {_vm.$set(item, "ratio", $$v)},expression:"item.ratio"}},[_c('template',{slot:"append"},[_vm._v("%")])],2)],1),_c('el-form-item',{staticStyle:{"width":"670px"},attrs:{"label":"奖罚原因","prop":'rewardPunishmentList.' + index + '.reason',"rules":{
            required: true,
            message: '奖罚原因不能为空',
          }}},[_c('el-input',{model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})],1),_c('i',{staticClass:"btn el-icon-remove-outline",on:{"click":function($event){return _vm.removeReward(index)}}})],1)})],2)]:[_c('hb-fragment',{directives:[{name:"layout-flex",rawName:"v-layout-flex",value:({ minWidth: 280, nodeNum: 3, maxWidth: 280 }),expression:"{ minWidth: 280, nodeNum: 3, maxWidth: 280 }"}],attrs:{"detailData":_vm.detailTop}}),_vm._l((_vm.formData.rewardPunishmentList),function(item,index){return _c('hb-fragment',{directives:[{name:"layout-flex",rawName:"v-layout-flex",value:({ minWidth: 280, nodeNum: 4, maxWidth: 280 }),expression:"{ minWidth: 280, nodeNum: 4, maxWidth: 280 }"}],key:index,attrs:{"detailData":_vm.detailBottom(item)}})})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }