<template>
  <el-dialog
    :visible="visible"
    class="start-task"
    ref="startTask"
    width="630px"
    @closed="closed"
    :append-to-body="true"
  >
    <el-tooltip slot="title" effect="dark" :content="title" placement="top-start">
      <span class="elli-span">{{ title }}</span>
    </el-tooltip>
    <el-form ref="form" :model="formData" :rules="rules" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="承诺交付时间" prop="expectedEndTime">
            <el-date-picker
              v-model="formData.expectedEndTime"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="解决方案" class="mb0">
        <el-input
          type="textarea"
          placeholder="请输入解决方案"
          v-model="formData.solution"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="default-footer-btn-wrapper flex-end hb-dialog__footer-btn" slot="footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button type="primary" size="small" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
const originForm = {
  id: undefined,
  expectedEndTime: undefined,
  solution: undefined,
};
export default {
  name: "startTask",
  data() {
    return {
      visible: false,
      loading: false,
      title: "",
      formData: cloneDeep(originForm),
      rules: {
        expectedEndTime: [{ required: true, message: "请选择承诺交付时间", trigger: "change" }],
      },
    };
  },
  methods: {
    open({ id, title }) {
      this.formData.id = id;
      this.title = title;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/task/startTask", this.formData)
            .then((res) => {
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.visible = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    closed() {
      this.formData = cloneDeep(originForm);
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 86400000;
    },
  },
};
</script>

<style lang="scss" scoped>
.elli-span {
  display: inline-block;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>